
import { defineComponent } from 'vue'

import WeblinkUserSyncTask from '@/components/taskComponents/weblinkUserSyncTask.vue'
import ImportArticlesTask from '@/components/taskComponents/importArticlesTask.vue'
import ClearCacheTask from '@/components/taskComponents/clearCacheTask.vue'

export default defineComponent({
  name: 'SystemTasksView',
  components: {
    WeblinkUserSyncTask,
    ImportArticlesTask,
    ClearCacheTask,
  },
})
