
import { coreClient } from '@/libs'
import { Options, Vue } from 'vue-class-component'
import MessagesComponent from '../Messages.vue'

@Options({
  name: 'ClearCacheTask',
})
export default class ClearCacheTask extends Vue {
  clearingCache = false
  clearCacheStatus: string | null = null

  async clearCache(): Promise<void> {
    try {
      this.clearCacheStatus = null
      this.clearingCache = true
      await coreClient.get('/api/system/clearCache')
      this.clearCacheStatus = 'Cachen rensad'
    } catch (err: unknown) {
      ;(this.$refs.errorHandler as MessagesComponent).pushErr(err)
    } finally {
      this.clearingCache = false
    }
  }
}
