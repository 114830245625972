
import { coreClient } from '@/libs'
import { Options, Vue } from 'vue-class-component'
import MessagesComponent from '../Messages.vue'

interface IStatusResponse {
  status: string
}

@Options({
  name: 'WeblinkUserSyncTask',
})
export default class WeblinkUserSyncTask extends Vue {
  updatingWeblinkUsers = false
  updateWeblinkUsersStatus: string | null = null

  async syncWeblinkUsers(): Promise<void> {
    this.updateWeblinkUsersStatus = null

    try {
      this.updatingWeblinkUsers = true
      const { data } = await coreClient.get<IStatusResponse>('/api/weblink/users/triggerUserSync')
      this.updateWeblinkUsersStatus = data.status
    } catch (err: unknown) {
      ;(this.$refs.errorHandler as MessagesComponent).pushErr(err)
    } finally {
      this.updatingWeblinkUsers = false
    }
  }
}
