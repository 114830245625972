
import { coreClient } from '@/libs'
import axios from 'axios'
import { Options, Vue } from 'vue-class-component'
import MessagesComponent from '../Messages.vue'

interface IStatusResponse {
  status: string
}

interface ICampaign {
  id: number
  name: string
  abbreviation: string
  active: boolean
}

@Options({
  name: 'ImportArticlesTask',
})
export default class ImportArticlesTask extends Vue {
  importingArticles = false
  importStatus: string | null = null

  loadingCampaigns = false
  campaigns: ICampaign[] = []
  selectedCampaign: ICampaign | null = null

  mounted(): void {
    this.fetchCampaigns().then((campaigns: ICampaign[]) => {
      this.campaigns = campaigns
    })
  }

  async fetchCampaigns(): Promise<ICampaign[]> {
    this.loadingCampaigns = true
    try {
      const { data } = await coreClient.get<ICampaign[]>(`/api/campaigns`)
      return data
    } catch (err: unknown) {
      ;(this.$refs.errorHandler as MessagesComponent).pushErr(err)
    } finally {
      this.loadingCampaigns = false
    }

    return []
  }

  importArticles(): void {
    if (this.selectedCampaign === null) {
      return
    }

    try {
      this.doImportArticles(this.selectedCampaign.id)
    } catch (err: unknown) {
      this.importStatus = err as string
    }
  }

  async doImportArticles(campaignId: number): Promise<void> {
    this.importingArticles = true
    this.importStatus = null

    try {
      const { data } = await coreClient.post<IStatusResponse>(`/api/system/import/campaigns/${campaignId}/articles`)
      this.importStatus = data.status
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        throw err.response?.data.error
      } else {
        throw 'Unknown error'
      }
    } finally {
      this.importingArticles = false
    }
  }
}
